import "./Button.css";
import "./App.css";

const Button = () => {
    return (
        <>
        <div class="outer button">
            <button>Apply</button>
            <span></span>
            <span></span>
         </div>
        </>
        
    )
}

export default Button; 